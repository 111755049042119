angular.module('enervexSalesappApp').controller('AddCustomLineitemModalCtrl', function($scope, $uibModalInstance, addProduct, isApplicationSetting, LineItemUtil) {
	$scope.isApplicationSetting = isApplicationSetting
	function resetNewItem() {
		$scope.newItem = {
			customProduct: {}
		}
		$scope.newItem.customProduct.unit = "EA"
		$scope.newItem.customProduct.price = 0
		$scope.newItem.quantity = 1
	}

	if (isApplicationSetting) {
		resetNewItem()
	}
	$scope.done = function($event) {
		$event.preventDefault()
		var modalInstance = $uibModalInstance.close();
	}
	
	$scope.addCustomProduct = function(item){
		if (isApplicationSetting) {
			var formatted = LineItemUtil.formatItem(item)
			addProduct(formatted)
			resetNewItem()
		} else {
			var itemCopy = item
			itemCopy.itemType = 'custom'
			addProduct(itemCopy)
			resetNewItem()
		}
	}

});
